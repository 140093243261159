import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "../styles/Wallet.scss";
import Cookies from "js-cookie";
// 이미지
import TokenType from "../components/TokenType";

const Wallet = () => {
  // 서버 API
  const serverApi = process.env.REACT_APP_AWS_API;
  // 네트워크 선택
  const { network } = useParams();
  // 토큰 정보
  const token = Cookies.get("token");
  // 조회후 매틱 가격 저장
  const [maticPrice, setMaticPrice] = useState(0);
  // 네트워크에 따라서 매틱 가격 단위 변경
  let walletChain;
  switch (network) {
    case "Polygon":
      walletChain = "MATIC";
      break;
    case "Avalanche":
      walletChain = "AVAX";
      break;
    case "Base":
      walletChain = "ETH";
      break;
    case "opBNB":
      walletChain = "BNB";
      break;
    case "Arbitrum":
      walletChain = "ETH";
      break;
    default:
      walletChain = "MATIC";
      break;
  }
  // 지갑 조회 함수
  const getWalletList = async () => {
    try {
      const res = await axios.get(
        `${serverApi}/api/cms/wallet_address/management/${network}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMaticPrice(res.data);
      console.log("지갑 조회 성공", res);
    } catch (error) {
      console.log("지갑 조회 실패");
    }
  };
  // 지갑 조회 함수 실행
  useEffect(() => {
    getWalletList();
  }, [network]);
  return (
    <>
      <div className="wallet">
        <div className="wallet--inner">
          <div className="header--box">
            <div className="main--title">
              지갑관리
              <TokenType tokenType={network} />
            </div>
          </div>
          {/* header--box --END-- */}
          <div className="wallet--list--box">
            <table>
              <thead>
                <tr>
                  {/* <th>번호</th> */}
                  <th>지갑주소</th>
                  <th>현재 보유 수량</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>0x9049Bc187d96D79b699d7d36D893C1826385B2d2</td>
                  <td>
                    {maticPrice} {walletChain}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Wallet;
